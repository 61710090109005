<template>
	<div>
		我是下载页
	</div>
</template>

<script>
	export default {
		name:'Download',
		data(){
			return {
				
			}
		}
	}
</script>

<style>
</style>
