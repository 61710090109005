<template>
	<div class="header">
		<div class="headerImg">
			<el-image :src="banner" fit="cover" class="img"></el-image>
		</div>
		<div class="hp-main">
			
			<div class="main">
				<div class="navTop">
					<div class="logo">
						<img :src="logoImg" class="logoImg">
					</div>
					<div class="tel">
						{{$t("login.serviceTelephone")}} {{compInfo.etServicePhone}}
					</div>
				</div>
				<div class="login-main">
					<div class="zc-left">
						<div class="zc-top">
							企业差旅管理专家
						</div>
						<div class="zc-center">
							Corporate Travel Management Expert
						</div>
						<div class="fw-lx">
							<div class="fw-left">
								<div class="fw-qy">· {{$t("login.enterpriseContact")}}</div>
								<div class="fw-num">400+</div>
							</div>
							<div class="fw-left">
								<div class="fw-qy">· {{$t("login.servicePersonTimes")}}</div>
								<div class="fw-num">80w</div>
							</div>
							<div class="fw-left">
								<div class="fw-qy">· {{$t("login.saveTravelServiceCB")}}</div>
								<div class="fw-num">30%</div>
							</div>
						</div>
						<div class="zc-menu">
							<el-button class="zcfx plain" @click="goToRegister(1)">{{$t("login.personRegister")}}</el-button>
							<el-button class="zcfx" type="primary" @click="goToRegister(2)">{{$t("login.corpRegister")}}</el-button>
						</div>
					</div>
					<div class="login-right">
						<el-menu :default-active="activeIndex" class="setBorder" @select="chooseCurrentType" mode="horizontal">
							<el-menu-item index="1">工号登录</el-menu-item>
							<el-menu-item index="2">手机登录</el-menu-item>
						</el-menu>
						<div>
							<div class="ghform" v-if="activeIndex=='1'">
								<el-form ref="form" key="form" :model="form" :rules="rules" label-width="0px">
									<el-form-item prop="corpNo">
										<el-input  :placeholder="$t('login.corpNo_holder')"
											v-model="corpNo" clearable>
											<i slot="prefix" class="iconfont yj-icon-qydh_icon" :class="{iconColor:form.corpNo}"></i>
										</el-input>
									</el-form-item>
									<el-form-item prop="name">
										<el-input :placeholder="$t('login.name_holder')" v-model="name" clearable>
											<i slot="prefix" class="iconfont yj-icon-user_icon" :class="{iconColor:form.name}"></i>
										</el-input>
									</el-form-item>
									<el-form-item prop="password">
										<el-input :placeholder="$t('login.password_holder')" v-model="form.password"
											type="password" show-password clearable>
											<i slot="prefix" class="iconfont yj-icon-pw_icon" :class="{iconColor:form.password}"></i>
										</el-input>
									</el-form-item>
									<el-form-item style="margin-bottom: 8px;">
										<el-button type="primary" class="button" @click="Login('form')">
											<span class="el-icon-loading" v-if="loading"></span>
										{{$t('login.button')}}</el-button>
									</el-form-item>
									<el-form-item style="margin-bottom: 5px;text-align: right;" >
										<!-- jtype="flex" ustify="space-between" -->
										<el-row  >
											<!-- <el-checkbox v-model="isMemery" style="color:#999999">{{$t('login.remember')}}
											</el-checkbox> -->
											<a href @click.prevent="openMsg" style="color:#999999;">{{$t('login.forget')}}</a>
										</el-row>
									</el-form-item>
								</el-form>
							</div>
							<div  class="sjform" v-if="activeIndex=='2'">
								<el-form ref="telForm" key="telForm" :model="telForm" :rules="telRules" label-width="0px">
									<el-form-item prop="phone" style="margin-bottom: 25px;">
										<el-input  :placeholder="$t('login.phone_tip')"
											v-model="telForm.phone" clearable>
											<i slot="prefix" class="el-input__icon el-icon-mobile-phone" :class="{iconColor:telForm.phone}"></i>
										</el-input>
									</el-form-item>
									<el-form-item prop="verifyCode" style="margin-bottom: 25px;">
										<el-input :placeholder="$t('login.verifyCode')" maxlength="6" v-model="telForm.verifyCode">
											<i slot="prefix" class="iconfont yj-icon-pw_icon" :class="{iconColor:telForm.verifyCode}"></i>
											<i slot="suffix" class="suffix-item" @click.prevent="getYzmCode">
												{{showCode?$t('login.resend')+'('+ timer +')':$t('login.getYzmCode')}}
											</i>
										</el-input>
									</el-form-item>
                  <el-form-item style="margin-bottom: 25px;">
                    <SliderVerify @success="isVerified = true" />
                  </el-form-item>
									<el-form-item style="margin-bottom: 25px;">
										<el-button type="primary" class="button" @click="Login('telForm')">
										<span class="el-icon-loading" v-if="loading"></span>
										{{$t('login.button')}}</el-button>
									</el-form-item>
								</el-form>
							</div>
						</div>
						<div class="login-footer">
							<p class="footer1" :class="{'shake-slow':isShake}">
								<span class="el-icon-success xy_icon" :class="{'icon_xy':isAgreement,}" @click="getAgreement"></span>
								<span>我已阅读并同意</span>
								<span class="xyColor" @click="getService(1)">《服务协议》</span>
								<span>和</span>
								<span class="xyColor" @click="getService(2)">《隐私条款》</span>
							</p>
							
							<!-- <p class="footer1">京ICP备17020943号-1</p> -->
						</div>
					</div>
				</div>
			</div>
			<yj-term-agreement :visible="isService" :params="params"  @cancelService="cancelService"></yj-term-agreement>
		</div>
	</div>
	
</template>

<script>
	import kgjh_logo from '@/assets/image/logo/kgjh_logo.png';
import yjsl_logo from '@/assets/image/logo/yjsl_logo.png';
import SliderVerify from '@/components/SliderVerify.vue';
import {
mapActions
} from "vuex";

	export default {
		name: 'Header',
    components: {
      SliderVerify
    },
		props:{
			compInfo:{
				type:Object,
				default(){
					return {};
				}
			}
		},
		data() {
			let valiPhone = (rule, value, callback) => {
			        var myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
			        if(!myreg.test(this.telForm.phone)){
						callback(new Error(this.$t("register.invalidPhone")));
			        }else {
						callback();
			        }	
			    };
			return {
				banner: require("@/assets/image/login/banner.jpg"),
        isVerified:false,
				form: {
					corpNo: '',//YJSL
					name: "",//SHC
					password: "",//123456
				},
				telForm:{
					phone: '',
					verifyCode:'',
				},
				isMemery: false, //记住密码
				rules: {
					corpNo: [{
						required: true,
						message: this.$t("login.corpNo_tip"),
						trigger: "blur"
						// validator: checkone
					}],
					name: [{
						required: true,
						message: this.$t("login.name_tip"),
						trigger: "blur"
						// validator: checkone
					}],
					password: [{
						required: true,
						message: this.$t("login.password_tip"),
						trigger: "blur"
					}]
				},
				telRules:{
					phone:[{required: true,message:this.$t("login.phone_tip"),trigger: "blur"},
							{validator:valiPhone,trigger: "blur"}],
					verifyCode:[{required: true,message:this.$t("login.verifyCode_tip"),trigger: "blur"}],
					
				},
				parameterData: {}, //企业参数信息

				activeIndex: '1', // 菜单切换
				
				showCode: false, // 验证码显示
				timer: 60, //验证码有效时间
				
				loginType: 1,// 登录类型
				loading: false,
				
				isAgreement: false, // 选中服务协议隐私条款

				isShake: false, // 抖动效果
				
				isService: false, // 显示服务协议
				
				isPrivacy: false, // 显示隐私条款
				params: 1, // 服务协议隐私条款显示参数 1服务协议 2隐私条款
				configData:{},// 公司信息
				settingConfigData:{},// 系统获取的
				passwordInfo:{},
			
				clearTime:'',//清除计时器
				logoImg:'',
				logoMap:{
					yjsl:{
						logoImg:yjsl_logo,
						logoTitle:'壹嘉商旅',
					},
					kgjh:{
						logoImg:kgjh_logo,
						logoTitle:'空港嘉华',
					},
					xjkgjh:{
						logoImg:require('@/assets/image/logo/jhyx_logo.png'),
						logoTitle:'嘉华易行',
					},
				}
			}
		},
		computed:{
			corpNo:{
				get(){
					return this.form.corpNo
				},
				set(val){
					this.form.corpNo = val.toUpperCase();
				}
			},
			name:{
				get(){
					return this.form.name
				},
				set(val){
					this.form.name = val.toUpperCase();
				}
			},
			
		},
		mounted() {
		
			this.configData = this.$common.getConfigData() || {};// 公司信息
			this.settingConfigData = this.$common.getPageSettingInfo(); // 系统配置问题
			if(this.configData.homePageType && this.logoMap[this.configData.homePageType]){
				this.logoImg = this.logoMap[this.configData.homePageType]['logoImg'] || '';
			}else if(this.configData.homePageType && !this.logoMap[this.configData.homePageType]){
				this.logoImg = this.settingConfigData.logoUrl || this.configData['logoImg'] || '';
			}else{
				this.logoImg = yjsl_logo || '';
			}
			// this.passwordInfo = this.$common.localget('passwordInfo') || '';
			let loginPersonInfo = this.$common.localget('loginPersonInfo') || {};
			let isAgreement = this.$common.localget('isAgreement') || {};
			if(loginPersonInfo.corpNo){
				this.form.corpNo = loginPersonInfo.corpNo.toUpperCase();
			}
			if(loginPersonInfo.name){
				this.form.name = loginPersonInfo.name.toUpperCase();
			}
			// if(this.passwordInfo){
			// 	this.form.password = this.passwordInfo;	
			// 	this.isMemery = true;
			// }
			if(isAgreement){
				this.isAgreement = isAgreement;
			}
			
			window.addEventListener('keydown',this.keyDown);
		},
		
		beforeDestroy(){
			clearTimeout(this.clearTime)
		},
		methods: {
			...mapActions({
				GETUserInfo: 'common/getUserInfo',
			}),
			keyDown(e){
			    //如果是回车则执行登录方法
			    if(e.keyCode == 13){
			        this.Login('form');
			    }
			},
			//选择登录类型
			chooseCurrentType(e){
				this.activeIndex = e;
				this.loginType = e;
				this.loading = false;
			},
			// 获取验证码
			getYzmCode(){
				if(!this.telForm.phone){
					this.$message.warning(this.$t("login.phone_tip"))
					return 
				}
        if(!this.isVerified){
          this.$message.warning('请滑动下方滑块到最右边后再获取验证码！')
          return;
        }
				if(this.showCode){
					return 
				}
				this.showCode = true;
				this.getTimer();
				let queryParams = {
						phoneNumber: this.telForm.phone,
						type:'登录',
						compId:this.configData.compId,
				}
				this.$conn.getConn('user.codeGet',{jsfile:false})(queryParams,(res)=>{
					
				},(err)=>{
					this.$message.error(err.tips)
				})
			},	
			// 计时器时间
			validationTime(){
				this.clearTime = setTimeout(()=>{
					if(this.timer == 0){
						this.showCode = false;
						this.timer = 60;
					}else{
						this.getTimer()
					}
				},1000)
			},
			// 倒计时
			getTimer(){
				this.timer--;
				this.validationTime()
			},
			// 注册 1个人 2企业
 			goToRegister(number){
				if(number==1){
					this.$router.push({path:'/personRegister'})
				}else{
					this.$router.push({path:'/corpRegister'})
				}
			},
			// 选中协议
			getAgreement(){
				this.isAgreement =(this.isAgreement?false:true);
				this.$common.localset('isAgreement',this.isAgreement)
			},
			// 获取服务协议
			getService(num){
				this.isService = true;
				this.params = num-0;
			},
			// 关闭服务协议 
			cancelService(bool){
				this.isService = bool;
			},
			//登录
			Login(formName) {
				if(!this.isAgreement){
					this.isShake = true;
					this.$message.warning(this.$t("login.serviceAndPrivacy"))
					setTimeout(()=>{
						this.isShake = false;
					},1000)
					return 
				}
				this.$refs[formName].validate(valid => {
					if (valid) {
						let obj = {
							corpNo: this.form.corpNo,
							name: this.form.name,
						}
						this.$common.localset('loginPersonInfo',obj)
						// if(this.isMemery){
						// 	this.$common.localset('passwordInfo',this.form.password)
						// }else{
						// 	this.$common.localremove('passwordInfo')
						// }
						
						
						this.loading = true;
						var queryString = {};
						if(this.loginType == 1){
							queryString.loginType = this.loginType;
							queryString.corpNo = this.form.corpNo;
							queryString.loginName = this.form.name;
							queryString.password = this.form.password;
						}else if(this.loginType == 2){
							queryString.loginType = this.loginType;
							queryString.compId = this.configData.compId;
							queryString.loginName = this.telForm.phone;
							queryString.password = this.telForm.verifyCode;
						}
						this.loading = true;
						
						if(this.$parent.toLoginAjax){
							// 调用父节点的方法
							this.$parent.toLoginAjax(queryString,{},(res)=>{
								if(res==false){
									this.loading = false;
								}
								clearTimeout(this.clearTime)
							},(err)=>{
								clearTimeout(this.clearTime)
								this.loading = false;
								this.$message.error(err.tips)
							})
						}
					}
				});
			},
			// 用户详情
			getUserInfo() {

				let vm = this;
				this.GETUserInfo({
					vm,
					cb: (data) => {
						this.$router.replace({
							name: "Home"
						})
					}
				})


			},
			getMenu() {
				this.$http.get("getMenu").then(res => {
					// 提取菜单数组，交给本地存储
					let menu = res.data.menu;
					// 将原始数据进行本地存储
					localStorage.menu = JSON.stringify(menu);
					// 解析出路由配置表
					const _routes = generateRoutes(menu);
					// 动态加载路由配置表
					router.addRoutes(_routes);
					// 跳转到首页
					this.$router.push("/notes");
				});
			},
			// 忘记密码
			openMsg() {
				this.$router.push({path:"/forgetPassword"})
				// 注意这里使用了国际化
				// this.$message.warning(this.$t("login.info"));
			},
			
		},
		
		destroyed(){
		        window.removeEventListener('keydown',this.keyDown,false);
		}
	}
</script>

<style lang="scss" scoped>
	.header{
		.headerImg{
			width:100%;
			height:644px;
			position: absolute;
			left:0;
			top:0;
			background-color: #333333;
			z-index: -10;
			.img{
				width:100%;
				height:100%;
				object-fit:cover;
			}
		}
		.hp-main {
			width: 100%;
			height: 644px;
			
			.main {
				width: 1200px;
				margin: 0 auto;
				font-family: '微软雅黑';
				.navTop {
					display: flex;
					justify-content: space-between;
					padding-top: 35px;
		
					.logo {
						// display: flex;
						// font-size: 22px;
						// line-height: 28px;
						// font-weight: 500;
						// color: #FFFFFF;
						max-height: 60px;
						max-width: 260px;
						.logoImg{
							max-height: 60px;
							max-width: 260px;
						}
						
					}
		
					.tel {
						font-size: 22px;
						font-weight: 100;
						color: #FFFFFF;
						line-height: 28px;
					}
		
				}
		
				.login-main {
					display: flex;
					justify-content: space-between;
					margin-top: 60px;
		
					.zc-left {
						.zc-top {
							font-size: 75px;
							font-weight: 100 !important;
							color: #FFFFFF;
							line-height: 75px;
							text-align: left;
						}
		
						.zc-center {
							height: 12px;
							font-size: 15px;
							font-weight: 400;
							color: #FFFFFF;
							line-height: 103px;
							opacity: 0.6;
							text-align: left;
							letter-spacing: 2px;
						}
		
						.fw-lx {
							display: flex;
							margin-top: 80px;
		
							.fw-left {
								font-weight: 400;
								color: #FFFFFF;
								text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
								text-align: left;
								margin-right: 85px;
		
								.fw-qy {
									font-size: 13px;
									line-height: 13px;
									text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
								}
		
								.fw-num {
									font-size: 37px;
									line-height: 37px;
									text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
									margin-top: 8px;
								}
							}
						}
		
						.zc-menu {
							text-align: left;
							margin-top: 154px;
		
							.zcfx {
								width: 166px;
								height: 69px;
								margin-right: 35px;
								border-radius: 0;
		
								font-size: 14PX;
								color: #FFFFFF;
							}
		
							.plain {
								background-color: transparent;
							}
		
							.plain:hover {
								color: #00A7FA;
								border: 1px solid #00A7FA;
							}
						}
					}
		
					.login-right {
						width: 345px;
						height: 448px;
						background: #FFFFFF;
						box-shadow: 0px 7px 7px 0px rgba(0, 0, 0, 0.08);
						position: relative;
						.el-menu {
							border-bottom: none;
							text-align: center;
						}
		
						.setBorder {
							display: inline-block;
		
							/deep/ .el-menu-item {
								font-size: 19px;
								font-weight: 500;
								color: #666666;
								line-height: 19px;
								padding: 27px 0 14px 0;
								margin: 0 20px;
							}
		
							.is-active {
								color: #343434;
								border-bottom: 4px solid #409EFF
							}
						}
		
						.ghform {
							padding: 19px 23px 0 23px;
							
							/deep/ .el-input__inner {
								// width: 299px;
								height: 46px;
								background: #FFFFFF;
								border: 1px solid #DBDBDB;
								border-radius: 2px;
								line-height: 46px;
								padding-left: 60px;
								cursor: pointer;
							}
		
							/deep/ .el-input__prefix {
								top: 1px;
								left: 1px;
								width: 46px;
								height: 44px;
								background: #F5F5F5;
								font-size: 18px;
								line-height: 44px;
								border-radius: 1px 0px 0px 1px;
								cursor: pointer;
							}
							/deep/ .iconColor{
								color: #007FE9;
							}
							.button{
								width: 100%;
								height: 50px;
								font-size: 17px;
								font-weight: 500;
								color: #FFFFFF;
								border-radius: 0;
								margin-top: 3px;
								text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.03);
								//background: #4E90F1;
							}
						}
						
						.sjform{
							padding: 21px 23px 0 23px;
							/deep/ .el-input__inner {
								height: 46px;
								background: #FFFFFF;
								border: 1px solid #DBDBDB;
								border-radius: 2px;
								line-height: 46px;
								padding-left: 60px;
								cursor: pointer;
							}
							.suffix-item{
								width: 100px;
								display: inline-block;
								text-align: center;
								cursor: pointer;
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: nowrap;
								border-left: 1px solid #DBDBDB;
							}
							/deep/ .el-input__prefix {
								top: 1px;
								left: 1px;
								width: 46px;
								height: 44px;
								background: #F5F5F5;
								font-size: 18px;
								line-height: 44px;
								border-radius: 1px 0px 0px 1px;
								cursor: pointer;
							}
							/deep/ .el-input__suffix{
							    line-height: 24px;
								top: 0px;
								right: 1px;
								padding: 10px 0;
							}
							/deep/ .iconColor{
								color: #007FE9;
							}
							.button{
								width: 100%;
								height: 50px;
								font-size: 17px;
								font-weight: 500;
								color: #FFFFFF;
								border-radius: 0;
								margin-top: 3px;
								text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.03);
								//background: #4E90F1;
							}
						}
						
						.login-footer{
							width: 100%;
							background: #F0F0F0;
							position: absolute;
							bottom: 0;
							left: 0;
							padding: 10px 0;
							.footer1{
								line-height: 40px;
								font-size: 12px;
								color: #888888;
								vertical-align: sub;
							}
							
							.xyColor{
								color: #007AFF;
								cursor: pointer;
							}
							.xyColor:hover{
								opacity: 0.7;
							}
							.xy_icon{
								font-size: 20px;
								color: #999999;
								padding-right: 5px;
								cursor: pointer;
							}
							.icon_xy{
								color: #007AFF;
							}
							@keyframes shake-slow {  
								0% {  transform: translate(0px, 0px);}  					
								10% {  transform: translate(8px, 0px);}
								20% {  transform: translate(0px, 0px);}
								30% {  transform: translate(7px, 0px);}
								40% {  transform: translate(0px, 0px);}   
								50% {  transform: translate(6px, 0px);}   
								60% {  transform: translate(0px, 0px);} 
								70% {  transform: translate(5px, 0px);}  
								80% {  transform: translate(0px, 0px);} 
								90% {  transform: translate(4px, 0px);} 
								100% {  transform: translate(0px, 0);} 
										 
							 }  
							.shake-slow{  animation:shake-slow 1s infinite ease-in-out; }
						}
					}
				}
		
				
			}
		}
	}
</style>
