<template>
  <div class="slider-container" v-show="visible" >
    <div class="slider-track" ref="track" :style="`background:${bgc}`"
         @mousedown="handleMouseDown"
         @mouseup="handleMouseUp"
         @mouseleave="handleMouseUp"
    >
      <!-- 背景层 -->
      <div class="slider-progress" :style="`width:${progressWidth}%;background-color:${successbgc};`"></div>

      <!-- 文字容器 -->
      <div class="text-container">
        <!-- 白色文字（仅显示滑块左侧部分） -->
        <div
            class="white-text"
            :style="{ clipPath: `inset(0 ${100 - progressWidth}% 0 0)` }"
        > {{ isVerified ? successText : text }}</div>
        <!-- 灰色文字（底层） -->
        <div class="gray-text"> {{ isVerified ? successText : text }}</div>
      </div>

      <!-- 滑块 -->
      <div
          class="slider-thumb"
          :style="{ left: `${thumbPosition}px` }"
      >
        <i class="iconfont thumb-icon"
           :style="`color:${isVerified?successbgc:'#666'};`"
           :class="[isVerified?'icon-bjck_dh':'icon-shuangxianyoujiantou']" ></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SliderVerify',
  props: {
    'text':{
      type: String,
      default: '请按住滑块，拖到最右边',
    },
    'successText':{
      type: String,
      default: '验证通过',
    },
    'bgc':{
      type: String,
      default: '#F0F2F5',
    },
    'successbgc':{
      type: String,
      default: '#18CE6D',
    },
    visible: { // 新增可见性控制prop
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      thumbPosition: 0,
      maxPosition: 300, // 初始值，mounted 中动态计算
      startX: 0,
      isDragging: false,
      isVerified: false,
      resizeObserver: null // 用于监听尺寸变化
    };
  },
  computed: {
    progressWidth() {
      return Math.min((this.thumbPosition / this.maxPosition) * 100, 100);
    },
  },
  mounted() {
    this.initTrackSize()
    // 监听轨道尺寸变化 (现代浏览器)
    if (typeof ResizeObserver !== 'undefined') {
      this.resizeObserver = new ResizeObserver(() => {
        this.initTrackSize();
      });
      this.resizeObserver.observe(this.$refs.track);
    } else {
      // 兼容方案：监听窗口变化
      window.addEventListener('resize', this.initTrackSize);
    }
  },
  beforeDestroy() {
    // 清理监听
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    } else {
      window.removeEventListener('resize', this.initTrackSize);
    }
  },
  methods: {
    // 初始化轨道尺寸
    initTrackSize() {
      this.$nextTick(() => {
        const track = this.$refs.track; // 通过 ref 获取当前实例的 DOM
        if (track) {
          this.maxPosition = track.offsetWidth - 48;
        }
      });
    },
    // 暴露外部调用方法
    refreshSize() {
      this.initTrackSize();
    },
    handleMouseDown(e) {
      if (this.isVerified) return;
      this.isDragging = true;
      this.startX = e.clientX - this.thumbPosition;
      document.addEventListener('mousemove', this.handleMouseMove);
    },
    handleMouseMove(e) {
      if (!this.isDragging) return;

      const offsetX = e.clientX - this.startX;
      this.thumbPosition = Math.max(0, Math.min(offsetX, this.maxPosition));

      if (this.thumbPosition >= this.maxPosition * 0.95) {
        this.handleSuccess();
      }
    },
    handleMouseUp() {
      if (!this.isVerified) this.thumbPosition = 0;
      this.isDragging = false;
      document.removeEventListener('mousemove', this.handleMouseMove);
    },
    handleSuccess() {
      this.isVerified = true;
      this.thumbPosition = this.maxPosition;
      this.$emit('success');
    }
  },
  watch: {
    visible: {
      handler(newVal) {
        if (newVal) {
          // 可见时初始化尺寸
          this.$nextTick(() => {
            this.initTrackSize();
          });
        }
      },
      immediate: true
    }
  },
};
</script>


<style scoped>
.slider-container {
  width: 100%;
}
/* 轨道样式 */
.slider-track {
  position: relative;
  width: 100%;
  height: 50px;
  overflow: visible;
}

/* 进度条背景 */
.slider-progress {
  height: 100%;
}
/* 文字容器 */
.text-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

/* 双文字层 */
.white-text, .gray-text {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  white-space: nowrap;
  pointer-events: none;
}

.white-text {
  color: white;
  z-index: 1;
}

.gray-text {
  color: #666;
  z-index: 0;
}

/* 滑块样式 */
.slider-thumb {
  position: absolute;
  left: 0;
  top: 0;
  width: 50px;
  height: 50px;
  background: #FFFFFF;
  box-shadow: 0 0 8px rgba(0,0,0,0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}
</style>
