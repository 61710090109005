<template>
	<div class="fp-main">
		<div class="passMain">
			<div class="pas-main">
				<div class="pnav">
					<div class="img">
						<img src="../../assets/image/passlogo.png">
					</div>
					<div class="zhmm">
						找回密码
					</div>
				</div>
				<div class="login" @click="toLogin">
					登录
				</div>
			</div>
		</div>
		<div class="con-main" >
			<!-- :style="{height:screenHeight+'px'}" 计算页面主体内容高度-->
			<div style="width:400px; margin: 0 auto;">
				<div class="stepMain">
					<div class="stepC">
						<div class="circular" :class="{bgCircular:active>=1}">
							1
							<div class="cribg" :class="{cribg1:active>=2}"></div>
							<div :class="{fdbg:active==1}"></div>
						</div>
						<div :class="{colorCir:active>=1}" style="margin-top: 10px;">验证身份</div>
						
					</div>
					<div class="stepC ">
						<div class="circular" :class="{bgCircular:active>=2}">
							2
						<div class="cribg" :class="{cribg1:active>=3}"></div>
						<div :class="{fdbg:active==2}"></div>
						</div>
						<div :class="{colorCir:active>=2}" style="margin-top: 10px;">设置新密码</div>
						
					</div>
					<div class="stepC">
						<div class="circular" :class="{bgCircular:active==3}">
							3
							<div :class="{fdbg:active==3}"></div>
						</div>
						<div :class="{colorCir:active==3}" style="margin-top: 10px;">完成</div>
					</div>
				</div>
			</div>
			<div style="margin: 30px 0;">
				<div v-if="active==1" class="iconfont yj-icon-sfyz_icon iColor"></div>
				<div v-if="active==2" class="iconfont yj-icon-szxmm_icon iColor"></div>
				<div v-if="active==3" class="iconfont yj-icon-zhwc_icon iColor"></div>
			</div>
			<div style="width:420px;margin: 0 auto;">
				<div class="marT20"  v-if="active==1">
					<el-form :model="form" key="form" :rules="rules" ref="form" label-width="0" class="demo-ruleForm">
						<el-form-item  prop="phoneNumber">
							<el-input v-model="form.phoneNumber" :placeholder="$t('login.phone_tip')">
								<i slot="prefix" class="el-input__icon el-icon-mobile-phone"  :class="{iconColor:form.phoneNumber}"></i>
							</el-input>
						</el-form-item>
						<el-form-item  prop="smsCode">
							<el-input v-model="form.smsCode" maxlength="6" :placeholder="$t('login.verifyCode_tip')">
								<i slot="prefix" class="el-input__icon el-icon-key" :class="{iconColor:form.smsCode}"></i>
								<i slot="suffix" class="suffix-item" @click.prevent="getYzmCode">
									{{showCode?$t('login.resend')+'('+ timer +')':$t('login.getYzmCode')}}
								</i>
							</el-input>
						</el-form-item>
            <el-form-item prop="verifyCode" style="margin-bottom: 25px;">
              <SliderVerify @success="isVerified = true" />
            </el-form-item>
						<el-button plain type="primary" class="button" @click="next('form')">下一步</el-button>
					</el-form>  
					
				</div>
				<div class="marT20"  v-if="active==2">
					<el-form :model="newForm" key="newForm" :rules="newRules" ref="newForm" class="demo-ruleForm">
						<el-form-item  prop="password">
							<el-input v-model="newForm.password" :placeholder="$t('login.passwordT1')" type="password">
								<i slot="prefix" class="el-input__icon el-icon-key" :class="{iconColor:newForm.password}"></i>
							</el-input>
						</el-form-item>
						<el-form-item  prop="confirmPassword">
							<el-input v-model="newForm.confirmPassword" :placeholder="$t('login.enterAgain')" type="password">
								<i slot="prefix" class="el-input__icon el-icon-key" :class="{iconColor:newForm.confirmPassword}"></i>
							</el-input>
						</el-form-item>
					</el-form>  
					<el-button plain type="primary" class="button" @click="nextPass('newForm')">下一步</el-button>
				</div>
				<div class="marT20" v-if="active==3">
					<div  class="buttons">
						新密码设置成功,请
						<el-button type="text" style="font-size: 16px;" @click="toLogin">重新登录</el-button>
					</div>
				</div>
						
			</div>
		
		</div>
		<!-- <div class="fp-footer">
			<div class="foorter-main">
				Copyright©2015-2016 - {{configData.corpName}}
			</div>
		</div> -->
	</div>
</template>

<script>
	import SliderVerify from "@/components/SliderVerify.vue";

  export default{
		name: 'ForgetPassword',
    components: {SliderVerify},
		data() {
			let valiPhone = (rule, value, callback) => {
			        var myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
			        if(!myreg.test(this.form.phoneNumber)){
						callback(new Error(this.$t("register.invalidPhone")));
			        }else {
						callback();
			        }	
			    };
			let valipassword = (rule, value, callback) => {
			        var pass = /(?!^[a-z]*$)(?!^[A-Z]*$)(?!^[0-9]*$)(?!^[_\W]*$)^[\w\u0000-\u00FF]\S+$/
			        if(!pass.test(this.newForm.password)){
						callback(new Error(this.$t("register.passwordType")));
			        }else if((this.newForm.confirmPassword && this.newForm.password) && (this.newForm.confirmPassword != this.newForm.password)){
						callback(new Error(this.$t("register.passwordAtypism")));
					}else {
						callback();
			        }	
			    };
			let valiConfirmPassword = (rule, value, callback) => {
			        var pass = /(?!^[a-z]*$)(?!^[A-Z]*$)(?!^[0-9]*$)(?!^[_\W]*$)^[\w\u0000-\u00FF]\S+$/
			        if(!pass.test(this.newForm.confirmPassword)){
						callback(new Error(this.$t("register.passwordType")));
			        }else if((this.newForm.confirmPassword && this.newForm.password) && (this.newForm.confirmPassword != this.newForm.password)){
						callback(new Error(this.$t("register.passwordAtypism")));
					}else {
						callback();
			        }	
			    };
			return {
				heightTop:0,
        isVerified:false,
				form:{
					phoneNumber:'',
					smsCode:'',
				},
				newForm:{
					password:'',
					confirmPassword:'',
				},
				showCode: false,
				timer: 60,
				rules:{
						phoneNumber:[{required: true,message:this.$t("login.phone_tip"),trigger: "blur"},
						{validator:valiPhone,trigger: "blur"}],
						smsCode:[{required: true,message:this.$t("login.verifyCode_tip"),trigger: "blur"}],
				},
				newRules:{
					password:[ {required: true,message:this.$t("login.password_tip"),trigger: "blur"},
								{max: 20, min:6,message:this.$t("register.passwordLeng"),trigger: "blur"},
								{validator:valipassword,trigger: "blur"}
								],
					confirmPassword:[{required: true,message:this.$t("login.password_tip"),trigger: "blur"},
								{max: 20, min:6,message:this.$t("register.passwordLeng"),trigger: "blur"},
								{validator:valiConfirmPassword,trigger: "blur"}],
				},
				active:1,
				clearTime:'',
			}

		},
		computed:{
			// 公司信息
			configData(){
				let configData = this.$common.getConfigData() || {};
				return configData
			},
		
		},
		beforeDestroy(){
			clearTimeOut(this.clearTime)
		},
		methods:{
			// 获取验证码
			getYzmCode(){
				if(!this.form.phoneNumber){
					this.$message.warning(this.$t("login.phone_tip"))
					return 
				}else{
					var myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
					if(!myreg.test(this.form.phoneNumber)){
						this.$message.warning(this.$t("register.invalidPhone"))
						return 
					}	
				}
        if(!this.isVerified){
          this.$message.warning('请滑动下方滑块到最右边后再获取验证码！')
          return;
        }
				if(this.showCode){
					return 
				}
				this.showCode = true;
				this.getTimer();
				let queryParams = {
						phoneNumber: this.form.phoneNumber,
						type:'找回密码',
						compId:this.configData.compId,
				}
				this.$conn.getConn('user.resetPassword',{jsfile:false})(queryParams,(res)=>{
				
				},(err)=>{
					this.$message.error(err.tips)
					
					this.showCode = false;
				})
				
				this.$conn.getConn('user.codeGet',{jsfile:false})(queryParams,(res)=>{
					
				},(err)=>{
					this.$message.error(err.tips)
					this.showCode = false;
				})
			},	
			// 计时器时间
			validationTime(){
				this.clearTime = setTimeout(()=>{
					if(this.timer == 0){
						this.showCode = false;
						this.timer = 60;
					}else{
						this.getTimer()
					}
				},1000)
			},
			// 倒计时
			getTimer(){
				this.timer--;
				this.validationTime()
			},
			// 下一步
			next(formName){
				this.$refs[formName].validate(valid=>{
					if(valid){
						let queryParams = {
								phoneNumber: this.form.phoneNumber,
								smsCode: this.form.smsCode,
							}
						this.$conn.getConn('user.codeCheck',{jsfile:false})(queryParams,(res)=>{
							this.active = 2
							clearTimeOut(this.clearTime)
							this.timer == 0;
						},(err)=>{
							clearTimeOut(this.clearTime)
							this.timer == 0;
							this.$message.error(err.tips)
						})
					}
				})
			},
			// 设置保存密码
			nextPass(formName){
				this.$refs[formName].validate(valid=>{
					if(valid){
						let queryParams = {
								phoneNumber: this.form.phoneNumber,
								smsCode: this.form.smsCode,
								pwd: this.newForm.password,
							}
						this.$conn.getConn('user.resetPwdTwo',{jsfile:false})(queryParams,(res)=>{
							this.active = 3;
						},(err)=>{
							this.$message.error(err.tips)
						})
					}
				})				
			},
			// 返回登录页
			toLogin(){
				this.$router.push("/")
			}
		}
	}
</script>

<style lang="scss" scoped>
	.fp-main{
		font-family: '微软雅黑';
		.fp-nav{
			width: 100%;
			height: 40px;
			background-color: #1C83ED;
			.nav-main{
				margin: 0 auto;
				width: 1200px;
				height: 40px;
				display: flex;
				justify-content: space-between;
				color: #FFFFFF;
				.title{
					margin-left: 10px;		
					font-size: 16px;
					line-height: 40px;
					
				}
					
				
			}
			
		}
		.passMain{
			width: 100%;
			height: 80px;
			background-color: #FFFFFF;
			box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.04);
			.pas-main{
				margin: 0 auto;
				width: 1200px;
				height: 80px;
				display: flex;
				justify-content: space-between;
				.pnav{
					display: flex;
					height: 80px;
					.img{
						padding: 19px 0;
					}
					.title{
						margin-left: 10px;		
						font-size: 20px;
						line-height: 80px;
					}
					.zhmm{
						line-height: 78px;
						font-size: 18px;
						margin-left: 30px;
					}
				}
				.login{
					width: 66px;
					height: 36px;
					background: #1C83ED;
					border-radius: 21px;
					color: #FFFFFF;
					line-height: 36px;
					margin: 22px 0;
					cursor: pointer;
				}
			}
		}
		.con-main{
			margin: 0 auto;
			width: 1200px;
			text-align: center;
			padding: 50px 0 0 0;
			background-color: #FFFFFF;
			margin-top: 10px;
			.pageheader{
				font-size: 20px;
				line-height: 36px;
				font-weight: 500;
				
			}
			.step{
				width: 600px;
				text-align: left;
				margin-top: 20px;
				margin-left: 40px;
			}
			.stepMain{
				text-align: center;
				display: flex;
				justify-content: space-between;
				.stepC{
					width:80px;
					text-align: center;
					.circular{
						position: relative;
						width: 38px;
						height: 38px;
						margin-left: 19px;
						background: #F0F0F0;
						border-radius: 50%;
						line-height: 38px;
						color: #808080;
						z-index: 10;
						.cribg{
							position: absolute;
							left:100%;
							top:50%;
							transform: translate(-0,-50%);
							width:122px;
							height: 6px;
							background-color:#F0F0F0;
							z-index: 2;
						}
						.cribg1{
							background-color:#1C83ED;
						}
						.fdbg{
							position: absolute;
							top: 50%;
							right: 50%;
							transform: translate(50%,-50%);
							width: 48px;
							height: 48px;
							background: #1C83ED;
							opacity: 0.1;
							border-radius: 50%;
							z-index: 1;
						}
					}
					.bgCircular{
						background: #1C83ED;
						color: #FFFFFF;
					}
					.colorCir{
						color: #1C83ED;
					}
					
				}
				
				
			}
			.iColor{
				font-size: 70px;
				color: #1C83ED;
			}
			.marT20{
				margin-top: 20px;
			}
			.el-input{
				width: 420px;
			}
			.success{
				font-size: 20px;
				line-height: 30px;
				color: #ffcc33;
				
				.icon{
					font-size: 30px;
					padding-right: 10px;
				}
			}
			/deep/ .el-input__inner {
				width: 420px;
				height: 54px;
				background: #FFFFFF;
				border: 1px solid #DBDBDB;
				border-radius: 2px;
				line-height: 54px;
				padding-left: 74px;
				font-size: 16px;
				cursor: pointer;
			}
			
			/deep/ .el-input__prefix {
				top: 1px;
				left: 1px;
				width: 54px;
				height: 52px;
				background: #F5F5F5;
				font-size: 20px;
				line-height: 54px;
				border-radius: 1px 0px 0px 1px;
				cursor: pointer;
			}
			/deep/ .iconColor{
				color: #007FE9;
			}
			.button{
				font-size: 15px;
				font-weight: 500;
				border-radius: 0;
				width: 420px;
				height: 54px;
				text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.03);
			}
			.buttons{
				font-size: 16px;
				padding-left: 40px;
				margin-top: 20px;
			}
			/deep/ .el-input__suffix{
				padding: 10px 0;
				line-height: 34px;
			}
			.suffix-item{
				width: 160px;
				display: inline-block;
				text-align: center;
				cursor: pointer;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				border-left: 1px solid #DBDBDB;
				font-size: 16px;
			}
			/deep/ .el-form-item__label{
				line-height: 44px;
			}
			/deep/ .el-form-item{
				margin-bottom: 25px;
			}
		}	
		// .fp-footer{
		// 	width: 100%;
		// 	height: 80px;
		// 	background-color: #424242;
		// 	.foorter-main{
		// 		margin: 0 auto;
		// 		width: 1200px;
		// 		height: 80px;
		// 		line-height: 80px;
		// 		color: #FFFFFF;
		// 	}
		// }
	}
</style>
